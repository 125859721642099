<template lang="pug">
section.mission
  .mission__layout
    .pd-container.framed
      .mission__header
        .mission__introduction
          h2.pd-h2.mission__title Our dedication&nbsp;
            br.hidden-sm-and-up
            | &amp; mission
          p We know first-hand your pain points and make it our everyday mission to be a part of your growth. The POD distribution platform is always improving as we grow alongside our brands and retailers - this is our dedication and our mission.
        .mission__cta
          nuxt-link.el-button.el-button--pd-primary(
            v-click="track"
            :to="{ name: 'about' }"
            title="About us")
            span About us
            i.bx.bx-right-arrow-alt

      marquee-ticker.mission__ticker(:mask="0" :speed="30" overflowed)
        .mission__wall
          .mission__wall-column(v-for="i, ik in columns" :key="ik")
            .mission__wall-card(
              v-for="j, jk in i" :key="jk" v-image="`${j.url}?pod`"
              :style="{ aspectRatio: j.ratio }")
</template>

<script>
import MarqueeTicker from './MarqueeTicker'

export default {
  components: {
    MarqueeTicker
  },

  data () {
    return {
      columns: [
        [
          { url: '/img/photos/1.webp', ratio: 0.84 },
          { url: '/img/photos/2.webp', ratio: 0.73 }
        ],
        [
          { url: '/img/photos/3.webp', ratio: 1 },
          { url: '/img/photos/4.webp', ratio: 1.14 }
        ],
        [
          { url: '/img/photos/5.webp', ratio: 0.68 },
          { url: '/img/photos/6.webp', ratio: 1.45 }
        ],
        [
          { url: '/img/photos/7.webp', ratio: 0.75 },
          { url: '/img/photos/8.webp', ratio: 1 }
        ],
        [
          { url: '/img/photos/9.webp', ratio: 1 },
          { url: '/img/photos/10.webp', ratio: 0.75 }
        ],
        [
          { url: '/img/photos/11.webp', ratio: 0.84 },
          { url: '/img/photos/12.webp', ratio: 0.73 }
        ],
        [
          { url: '/img/photos/13.webp', ratio: 1 },
          { url: '/img/photos/14.webp', ratio: 1.14 }
        ],
        [
          { url: '/img/photos/15.webp', ratio: 0.68 },
          { url: '/img/photos/16.webp', ratio: 1.45 }
        ]
      ]
    }
  },

  methods: {
    track () {
      this.$amplitude.track('REBRANDING_PAGE_ACTION', {
        type: 'click_about_us_cta',
        zone: 'home_mission_section'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mission {
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  max-width: 100vw;
  padding: 32px 0 40px;
  @include media(sm) {
    padding: 80px 0 40px;
  }
  @include media(lg) {
    padding: 140px 0 80px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    gap: 32px;
    @include media(md) {
      gap: 48px;
      flex-direction: row;
      align-items: flex-end;
    }
  }
  &__introduction {
    flex: 1;
    p {
      line-height: 160%;
      color: $--color-text-secondary;
      margin: 32px 0 0 0;
      max-width: 720px;
      font-size: 16px;
      @include media(md) {
        font-size: 18px;
      }
    }
  }
  &__title {
    margin: 0;
  }
  &__ticker {
    margin-top: 40px;
    @include media(sm) {
      margin-top: 64px;
    }
    @include media(md) {
      margin-top: 80px;
    }
  }
  &__wall {
    display: flex;
    gap: 16px;
    @include media(md) {
      gap: 24px;
    }
    &::v-deep(.marquee-ticker__inner) {
      gap: 16px;
      @include media(md) {
        gap: 24px;
      }
    }
    &-column {
      display: flex;
      flex-direction: column;
      flex: 0 240px;
      min-width: 240px;
      gap: 16px;
      @include media(md) {
        flex: 0 280px;
        min-width: 280px;
        gap: 24px;
      }
    }
    &-card {
      width: 100%;
      border-radius: 12px;
      background-size: cover;
      background-position: center;
    }
  }
}
</style>
